@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  font-weight: 600 !important;
  color: #002768;
}

.btn-primary:focus{
  box-shadow:none !important;
  
}
.btn-primary:hover{
  box-shadow:none !important;
  color: white !important;
}
.btn{
  border: none;
}

/************************************************************************************/

/* Theam setting */

h1{
  font-size: 42px !important;
  font-weight: 600 !important;
}
h3{
  font-size: 25px !important;
  font-weight: 600 !important;
}
h4{
  font-size: 17px !important;
  font-weight: 600 !important;
}
h5{
  font-size: 16px !important;
  font-weight: 600 !important;
}
h6{
  font-size: 14px !important;

}

/* font weight */
.fw-6{
  font-weight: 600 !important;
}

/* font size */
.fs-4{
  font-size: 28px !important;
}
.fs-5{
  font-size: 16px !important;
}
.fs-menu{
  font-size: 20px !important;
}
.fs-small{
  font-size: 13px !important;
}
.fs-small-sm{
  font-size:11px !important;
}
/* text color */
.text-blue{
  color: #002768;
}

/* bg color */
.bg-blue{
  background-color: #002768;
}
.bg-danger{
  background-color: #ff5454;
}
.bg-black{
  background-color:#1e1e1e;
}
.bg-secondary{
  background-color:#ccd4e1 !important;
}
.bg-cyan {
  background-color:#15b097 !important;
}
.bg-invoice{
  background-color:#DDE0D5 !important;
}

.rounded-4{
  border-radius: 10px !important;
}
.rounded-5{
  border-radius: 25px !important;
}


.border-bottom{
  border-bottom: 2px dotted black !important;
}
.border-bottom-1{
  border-bottom: 2px solid black!important;
}

.border-blue{
  border-color: #002768 !important;
}
/************************************************************************************/

/* SideBar */

.sidebar{
  background-color:#002768;
  width: 20%;
}
.list-group-flush{
  margin-top:0px;
}
.menu_item{
  background-color:#002768;
  border: none;
  border-radius: 7px !important;

}
.nav .list-group-item .menu_link {
  border-top: 1px solid transparent;
  color:#ffffff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: 1px center;
  padding: 13px 10px 7px 42px;
  display: block;
  margin-bottom: 9px;
}
.menu_link{
  text-decoration: none;
  color: #ffffff;
  
}
.nav .list-group-item .nav-img1 {
  background-image: url('./assests/recent-repeat-icon2.svg');
}
/* .nav .list-group-item .nav-img2 {
  background-image: url('./assests/party1.svg');
} */
.nav .list-group-item .nav-img3 {
  background-image: url('./assests/party1.svg');
}


.menu_item:hover{
 background-color: #ffffff;
 .menu_link{
  color:#002768;
 }
}
.nav .list-group-item .nav-img1:hover {
  background-image: url('./assests/recent-repeat-icon.svg');
}
.nav .list-group-item .nav-img2:hover {
  background-image: url('./assests/party2.svg');
}
.nav .list-group-item .nav-img3:hover {
  background-image: url('./assests/party2.svg');
}


/* User */

.view-btn{
  width: 95px;
}
.overflowScroll{
  overflow-y:scroll ;
 scrollbar-width: 0px;
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}.shadow.shadow-md.usercard.p-3.rounded.rounded-4.card {
  flex-direction: row !important;
  
}

.shadow.shadow-md.usercard.p-3.rounded.rounded-4.card img.card-img { width:102px !important;}

/* Parties */
.form-control:focus{
  box-shadow: none;
  
}
.form-select:focus{
  box-shadow: none;
  
}

.modal-content{
  border-radius: 15px !important;
}
.rounded-top-4{
    border-radius: 15px 15px 0px 0px;
}

/* Trips */
.dropdown-menu{
  transform: translate(-1px, 49px) !important;
  box-shadow: 1px 2px 5px 1px rgb(212, 211, 211);
}

.btn-primary:hover {
   color: #2d2e30 ;
   background-color: #002768;
}


.form-check-input:checked{background-color: #002768 !important;}
.form-check-input:focus {box-shadow: none !important;}
::placeholder{color:black !important;}


.table-border {border: 1px solid #000000;}


.menu_link_active {
  color:#002768 !important;
 }
 .menu_item_active {
  background-color: #ffffff;
 }
 .nav_img3_active {
   background-image: url('./assests/party2.svg') !important;
 }
 .nav_img1_active {
   background-image: url('./assests/recent-repeat-icon.svg') !important;
 }
 /* .nav_img2_active {
   background-image: url('./assests/party2.svg') !important;
 }
 .nav_img4_active {
   background-image: url('./assests/sup2.svg') !important;
 }
 .nav_img5_active {
   background-image: url('./assests/driver2.svg') !important;
 }
 .nav_img6_active {
   background-image: url('./assests/truck2.svg') !important;
 } */

 .user_menu_dropdown {
  left: auto !important;
  top: 0px !important;
  right: 0px !important;
}
.bg-consignor {
  background-color: #d4e38b;
}
.bg-consignee {
  background-color: #c1adce;
}

.overflowy{
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.modal {
  display: none;
  /* Modal styling here */
}

.modal.show {
  display: block;
  /* Show modal styling here */
}
.display-none{
  display: none !important;
}
.display-block{
  display: block !important;
}